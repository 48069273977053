.logoLoko {
  width: 185px;
  position: relative;
  top: 100px;
  margin: 0 auto;
}

.logoShumomer {
  width: 790px;
  position: relative;
  top: 80px;
  margin: 0 auto 0;
}

.titleStart {
  text-align: center;
}

.titleStart img.letters {
  top: 607px;
  position: absolute;
  z-index: 2;
  left: 173px;
}

.titleStart img.stripes {
  top: 665px;
  position: absolute;
  z-index: 1;
  left: 173px;
}
