:global(body.client) {
  background: url("./assets/background.jpg");
  overflow: hidden;
}

.labelNewRecordSeason {
  font-family: 'Akrobat', sans-serif;
  font-weight: 900;
  line-height: 1.15;
  font-size: 115px;
  letter-spacing: 15px;
  text-transform: uppercase;
  color: white;
  position: absolute;
  top: 200px;
  left: 120px;
}

.labelRecordSeason {
  font-family: 'Akrobat', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 60px;
  letter-spacing: 10px;
  color: #fff;
  top: 315px;
  left: 120px;
  position: absolute;
  text-shadow: 0 0 15px rgba(255,137,1,.5), 0 0 10px rgba(255,137,1,.5);
  opacity: 0;
}

.recordSeason {
  font-family: 'Akrobat', sans-serif;
  font-weight: 900;
  line-height: 1;
  padding-right: 70px;
  color: #ea0a2a;
  position: absolute;
  left: 120px;
  top: 370px;
  opacity: 0;
}

.recordSeasonLabel {
  font-size: 306px;
}

.recordSeasonMetric {
  font-size: 95px;
}

.logosNewRecord {
  position: absolute;
  left: 120px;
  top: 700px;
  img {
    height: 150px;
  }
}

.logosRecord {
  position: absolute;
  left: 120px;
  top: 730px;
  img {
    height: 150px;
  }
}

.bigCircle {
  $size: 1260px;
  width: 0;
  height: 0;
  border-radius: $size;
  position: absolute;
  right: -300px;
  top: -90px;
}

.bigCircleRed {
  background-color: #ea0a2a;
}

.bigCircleGreen {
  background-color: #006e45;
}

.bigCircleInside {
  $size: 1500px;
  width: $size;
  height: $size;
  border-radius: $size;
  position: absolute;
  background: transparent;
  border: 60px solid rgba(255,255,255,.58);
  right: -530px;
  top: -265px;
  clip: rect(0, 0, 0, 0);
  box-sizing: content-box;
}

.bigCircleOutside {
  $size: 1750px;
  width: $size;
  height: $size;
  border-radius: $size;
  position: absolute;
  background: transparent;
  border: 5px solid rgba(255,255,255,1);
  top: -335px;
  right: -548px;
  clip: rect(0, 0, 0, 0);
}

.resultWrapper {
  color: white;
  position: absolute;
  right: 0;
  top: 180px;
  z-index: 3;
}


.labelResult {
  font-family: 'Akrobat', sans-serif;
  text-align: center;
  font-weight: 900;
  font-size: 100px;
  letter-spacing: 20px;
  margin-top: 35px;
  opacity: 0;
}

.labelResultRed {
  text-shadow: 0 0 15px rgba(255,255,255,.5), 0 0 10px rgba(255,255,255,.5);
}

.labelResultGreen {
  text-shadow: 0 0 15px rgba(255,255,255,.5), 0 0 10px rgba(255,255,255,.5);
}

.result {
  font-family: 'Akrobat', sans-serif;
  font-weight: 900;
  line-height: 1;
  padding-right: 70px;
  opacity: 0;
}

.resultValue {
  font-size: 523px;
  display: inline-block;
}

.resultMetric {
  font-size: 160px;
  display: inline-block;
  position: relative;
  left: 15px;
}
